


/* ==========================================================
AJAX: Add to whithlist
===========================================================*/
$(".add_to_withlist").on('click', function() {
    var product_id = $(this).attr('data-id');
    alert(product_id);
    return false;
})

/* ==========================================================
AJAX: Add item to cart xxxx
===========================================================*/
    
$(".add_to_cart").on('click', function (){
        
    var product_id = $(this).attr('data-id');
    var product_price = $(this).attr('data-price');

    $.ajax({
        url: "/products/ajax.shop.php", // execute target file
        type: "POST",
        data:{ 'product_id': product_id, 'product_price': product_price  },
        success: function(response){ 
            
            //console.log(response.count);
            if(response.count < 0){
                
                // ถ้า count = -1 แสดงว่ายังไม่ได้ login
                // ให้ Redirect ไปหน้าล๊อกอิน
                 var alert = "โปรดล๊อคอินเข้าระบบ"  
                $(location).attr('href', '/?user=login'); 
                
            }else if(response.count > 0){
                
                // ถ้าล๊อกอินแล้ว เพิ่มรายการลง Cart และอัพเดท Tools box
                if( response.count > 9){ response.count = '9+'; }
                $('#carts-noti-div').html(response.count).removeClass('empty');
                //$(".add_to_cart").hide();
                var alert = "เพิ่มรายการนี้ใน Cart ของคุณแล้ว" 

            }else{
                
                // กรณีที่มี Item อยู่ในการ์ดอยู่แล้ว
                $('#carts-noti-div').html('').addClass('empty');
                var alert = "มีรายการนี้ใน Cart ของคุณแล้ว" 
            }
            
            // แจ้งเตือนบน topbar popup 1.2 วินาที และซ่อนตัวเอง
            $('#msg').removeClass('hide');
            $('#msg .content').html( alert );
            $('#msg').slideDown('fast').popup('show');
            setTimeout(function(){
                $('#msg').slideUp('slow').popup('hide');
            }, 2000);                
            // Show Message            
        }
    });  
    return false;
});


// End Script

